import { Avatar, Button, Dialog, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import useStyles from "./style";
import { withRouter } from "react-router";
import SpinnerLoading from "../spinnerLoading/SpinnerLoading";
import payPaymentService, { stripeKey } from "~/services/payPaymentService";
import bookingTransactionService, { getOneBookingTransaction } from "~/services/bookingTransactionService";
import Utils from "~/helpers/Utils";
import _ from "lodash";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { DialogBuilder } from "~/utils/DialogProvider";
import businessService from "~/services/businessService";
import DialogError from ".";

const DialogPayConfirm = (props) => {
	const { i18n, t } = useTranslation();
	const dialog = useContext(DialogBuilder);
	const [clientSecret, setClientSecret] = useState(null);
	const [stripePromise, setStripePromise] = useState(null);
	const [errorMessage, setErrorMessage] = useState("Erro inesperado, tente novamente.");
	const [errorOpen, setErrorOpen] = useState(false);

	const appearance = {
		theme: "stripe",
	};

	const options = {
		clientSecret: clientSecret,
		appearance,
	};

	const showError = (message) => {
		setErrorMessage(message);
		setErrorOpen(true);
	};

	const {
		postPayment,
		onClose,
		cardData,
		pix,
		checkoutInformation,
		totalPricingType,
		currency,
		coupon,
		payload,
		postBooking,
		setPixCode,
		showUpdateInfo,
	} = props;

	const isPostPayment = !_.isNil(postPayment) && !_.isObject(postPayment) && _.has(postPayment, "booking_external_id");

	const mixpanel = useMixpanel();
	const classes = useStyles();
	const { user } = useSelector((store) => store.auth);
	const { business } = useSelector((store) => store.business);
	const { me } = useSelector((state) => state.me);
	const [loading, setLoading] = useState(false);

	const publishBookingtranscation = async (bookTransactionId) => {
		try {
			const bookingTransactionPublishRequest = await bookingTransactionService.publishBooktranscation(
				bookTransactionId
			);
			return bookingTransactionPublishRequest.data;
		} catch (err) {}
	};

	const submitPayment = async () => {
		setLoading(true);
		if (postPayment) {
			postPay(postPayment.booking_external_id, postPayment.recipient_id, postPayment.payment_request_id);
		} else {
			postBooking()
				.then(async (book) => {
					pay(book.data.bookings[0].bookingTransactionId, business.id);
				})
				.catch((err) => {
					showError(err?.response?.data?.message || "Verifique sua conexão e tente novamente");
					setLoading(false);
				});
		}
	};

	const postPay = (bookingTransactionId, recipient_id, payment_request_id) => {
		const meData = JSON.parse(localStorage.getItem("@prit-web/meData"));
		businessService
			.getBusiness(business.id)
			.then(async (res) => {
				const businessUpdated = res.data;
				const postalCode = businessUpdated?.address?.postalCode?.trim();
				if (
					businessUpdated.phone.startsWith("+55") &&
					postalCode &&
					!/^\d{8}$/.test(postalCode.replace("-", ""))
				) {
					showError(t("adress problems"));
					setLoading(false);
					return;
				}
				const address = businessUpdated.address || business.adress;
				const paypamentPay = {
					booking_external_id: bookingTransactionId,
					recipient_id: recipient_id,
					payment_request_id: payment_request_id,
					card: cardData && {
						card_holder_name: cardData.name,
						card_expiration_date: cardData.expiry,
						card_number: cardData.number,
						card_cvv: cardData.cvc,
					},
					client: {
						id: user?.id || me?.id || localStorage.getItem("@prit-web/userId") || meData?.id,
						name:
							me?.name ||
							user?.displayName ||
							cardData?.name ||
							localStorage.getItem("@prit-web/userName") ||
							meData?.name ||
							"Usuário Prit",
						email:
							user?.email ||
							cardData?.email ||
							localStorage.getItem("@prit-web/userEmail") ||
							me?.email ||
							meData?.email ||
							"contato@prit.app",
						country: "br",
						documents: [cardData ? cardData.cpf : pix],
						phone_numbers: me?.phone ? [me.phone] : meData?.phone ? [meData?.phone] : ["+551640420575"],
						address: {
							street: address?.street || "Av. Prof. João Fiúsa",
							complementary: address?.unit || "",
							street_number: address?.number || "1901",
							neighborhood: address?.neighborhood || "Jardim Botânico",
							city: address?.city || "Ribeirão Preto",
							state: address?.state || "SP",
							zipcode: address?.postalCode || "14024250",
							country: "br",
						},
					},
					amount: postPayment.amount / 100,
					discount: checkoutInformation?.total_discounts / 100,
					payment_type: pix ? 5 : null,
				};
				if (coupon) {
					paypamentPay.coupon_id = coupon.coupon_id;
				}
				if (currency !== "BRL") {
					const res = await payPaymentService.postPayIntent(paypamentPay);
					setClientSecret(res.data.payment_intent_client_secret);
					setStripePromise(loadStripe(stripeKey, { stripeAccount: res.data.connect_account_id }));
				} else {
					payPaymentService
						.postAdvancedPaymentPay(paypamentPay)
						.then((paymentPayRequest) => {
							if (pix) {
								let waitingPixPayment = setInterval(() => {
									getOneBookingTransaction(bookingTransactionId).then((res) => {
										let status = res.data.paymentStatus;
										if (status !== "pending") {
											if (status === "partial" || status === "completed") {
												mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
													my_custom_prop: "foo",
												});
												mixpanel.track(`Estabelecimento/web-${business?.name}`, {
													my_custom_prop: "foo",
												});
												props.history.replace("/notificacoes/" + business?.id, {
													paymentSuccess: true,
												});
											}
											clearInterval(waitingPixPayment);
										}
									});
								}, 5000);
								setPixCode(paymentPayRequest.data.pix_qr_code);
								setLoading(false);
							} else {
								mixpanel.identify(me?.phone ? me?.phone : me?.uid, {
									my_custom_prop: "foo",
								});
								mixpanel.track("Pagamento posterior (cartão)", {
									my_custom_prop: "foo",
								});
								mixpanel.track(`Estabelecimento/web-${business?.name}`, {
									my_custom_prop: "foo",
								});
								props.history.replace("/notificacoes/" + business?.id, {
									paymentSuccess: true,
								});
								setLoading(false);
							}
						})
						.catch((err) => {
							if (!isPostPayment) {
								bookingTransactionService.putBookingTransaction(bookingTransactionId);
							}
							showError(err?.response?.data?.message || err?.message || "Verifique sua conexão e tente novamente");
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				showError(err?.response?.data?.message || err?.message || "Verifique sua conexão e tente novamente");
				setLoading(false);
			});
	};

	const pay = (bookingTransactionId, recipient_id) => {
		const meData = JSON.parse(localStorage.getItem("@prit-web/meData"));
		businessService
			.getBusiness(business.id)
			.then(async (res) => {
				const businessUpdated = res.data;
				const postalCode = businessUpdated?.address?.postalCode?.trim();
				if (
					businessUpdated.phone.startsWith("+55") &&
					postalCode &&
					!/^\d{8}$/.test(postalCode.replace("-", ""))
				) {
					showError(t("adress problems"));
					setLoading(false);
					return;
				}
				const address = businessUpdated.address || business.adress;
				const paypamentPay = {
					booking_external_id: bookingTransactionId,
					recipient_id: recipient_id,
					card: cardData && {
						card_holder_name: cardData.name,
						card_expiration_date: cardData.expiry,
						card_number: cardData.number,
						card_cvv: cardData.cvc,
					},
					client: {
						id: user?.id || me?.id || localStorage.getItem("@prit-web/userId") || meData?.id,
						name:
							me?.name ||
							user?.displayName ||
							cardData?.name ||
							localStorage.getItem("@prit-web/userName") ||
							meData?.name ||
							"Usuário Prit",
						email:
							user?.email ||
							cardData?.email ||
							localStorage.getItem("@prit-web/userEmail") ||
							me?.email ||
							meData?.email ||
							"contato@prit.app",
						country: "br",
						documents: [cardData ? cardData.cpf : pix],
						phone_numbers: me?.phone ? [me.phone] : meData?.phone ? [meData?.phone] : ["+551640420575"],
						address: {
							street: address?.street || "Av. Prof. João Fiúsa",
							complementary: address?.unit || "",
							street_number: address?.number || "1901",
							neighborhood: address?.neighborhood || "Jardim Botânico",
							city: address?.city || "Ribeirão Preto",
							state: address?.state || "SP",
							zipcode: address?.postalCode || "14024250",
							country: "br",
						},
					},
					amount: checkoutInformation?.anticipation_payment / 100,
					discount: checkoutInformation?.total_discounts / 100,
					payment_type: pix ? 5 : null,
				};
				if (coupon) {
					paypamentPay.coupon_id = coupon.coupon_id;
				}
				if (currency !== "BRL") {
					const res = await payPaymentService.postPayIntent(paypamentPay);
					setClientSecret(res.data.payment_intent_client_secret);
					setStripePromise(loadStripe(stripeKey, { stripeAccount: res.data.connect_account_id }));
				} else {
					publishBookingtranscation(bookingTransactionId);
					payPaymentService
						.postPaymentPay(paypamentPay)
						.then((paymentPayRequest) => {
							if (pix) {
								let waitingPixPayment = setInterval(() => {
									getOneBookingTransaction(bookingTransactionId).then((res) => {
										let status = res.data.paymentStatus;
										if (status !== "pending") {
											if (status === "partial" || status === "completed") {
												mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
													my_custom_prop: "foo",
												});
												mixpanel.track(`Estabelecimento/web-${business?.name}`, {
													my_custom_prop: "foo",
												});
												props.history.push(`/reservas/${business.id}?pixConfirmation=${bookingTransactionId}`);
											}
											clearInterval(waitingPixPayment);
										}
									});
								}, 5000);
								setPixCode(paymentPayRequest.data.pix_qr_code);
								setLoading(false);
							} else {
								const newWebScheduling = "Novo agendamento web";
								mixpanel.identify(business.phone ? business.phone : "", {
									my_custom_prop: "foo",
								});
								mixpanel.track(newWebScheduling, {
									my_custom_prop: "foo",
								});

								mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : user?.uid, {
									my_custom_prop: "foo",
								});
								props.history.push(`/confirmation/${business?.id}`, {
									checkout: checkoutInformation,
									business: business,
									payload: payload,
									couponValue: coupon,
									totalPricingType: totalPricingType,
								});
								mixpanel.track("Acesso WEB", {
									my_custom_prop: "foo",
								});
								mixpanel.track("Agendamento confirmado-web", {
									my_custom_prop: "foo",
								});
								mixpanel.track(`Estabelecimento/web-${business?.name}`, {
									my_custom_prop: "foo",
								});
								setLoading(false);
							}
						})
						.catch((err) => {
							if (!isPostPayment) {
								bookingTransactionService.putBookingTransaction(bookingTransactionId);
							}
							let message = err?.response?.data?.message || err?.message;
							if (message && message.includes && message.includes("email")) {
								showError("O email informado é inválido, por favor, verifique e tente novamente");
							} else {
								showError(message || "Verifique sua conexão e tente novamente");
							}
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				let message = err?.response?.data?.message || err?.message;
				if (message && message.includes && message.includes("email")) {
					showError("O email informado é inválido, por favor, verifique e tente novamente");
				} else {
					showError(message || "Verifique sua conexão e tente novamente");
				}
				setLoading(false);
			});
	};

	const footer = () => {
		return (
			<div style={{ padding: "0 15px 15px 15px" }}>
				<Grid container justify="center">
					<Grid item xs={12} container justify="center">
						<Button onClick={submitPayment} className={classes.root}>
							Fazer pagamento
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	};

	const CheckoutForm = () => {
		const stripe = useStripe();
		const elements = useElements();
		const [isLoading, setIsLoading] = useState(false);

		const [openCheckoutStripe, setOpenCheckoutStripe] = useState(true);

		useEffect(() => {
			if (!stripe) {
				return;
			}
			const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
			if (!clientSecret) {
				return;
			}

			stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
				switch (paymentIntent.status) {
					case "succeeded":
						// dialog.withMessage("Payment succeeded!").show();
						break;
					case "processing":
						// dialog.withMessage("Your payment is processing.").show();
						break;
					case "requires_payment_method":
						// dialog.withMessage("Your payment was not successful, please try again.").show();
						break;
					default:
						// dialog.withMessage("Something went wrong.").show();
						break;
				}
			});
		}, [stripe]);

		const handleSubmit = async (e) => {
			e.preventDefault();

			if (!stripe || !elements) {
				return;
			}

			setIsLoading(true);

			const { error } = await stripe.confirmPayment({
				elements,
				confirmParams: {
					return_url: `https://web.pritweb.app/confirmation/${business?.id}`,
				},
			});

			if (error) {
				if (
					error.message !== "O número do seu cartão está incompleto." &&
					error.message !== "A data de expiração do seu cartão está incompleta." &&
					error.message !== "O código de segurança do seu cartão está incompleto."
				) {
					// dialog.withMessage(error.message).show();
				}
			} else {
				// if (bookingOkId) {
				// 	postBooking();
				// } else {
				// 	dialog.withMessage("booking não gerado").show();
				// }
			}

			setIsLoading(false);
			// setOpenCheckoutStripe(false);
		};

		return (
			<Dialog
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 312, minHeight: 430 },
				}}
				open={clientSecret && stripe && openCheckoutStripe}
				title={t("Confirm payment")}>
				<DialogTitle>
					<TitleDialog onClose={onClose} message={t("Confirm payment")} />
				</DialogTitle>
				<div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
					<form id="payment-form" onSubmit={handleSubmit}>
						<PaymentElement id="payment-element" />
						<button
							style={{
								backgroundColor: "#3689ea",
								color: "#ffffff",
								height: 44,
								width: "100%",
								fontSize: 12,
								fontWeight: "normal",
								textTransform: "none",
								borderRadius: 20,
								marginTop: "45px",
								borderColor: "transparent",
								"&:hover": {
									backgroundColor: "#106ba3",
								},
							}}
							disabled={isLoading || !stripe || !elements}
							id="submit">
							{isLoading ? <SpinnerLoading loading={true} /> : "Efetuar pagamento"}
						</button>
					</form>
				</div>
			</Dialog>
		);
	};

	return (
		<>
			{clientSecret && stripePromise && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm />
				</Elements>
			)}
			<DialogError
				message={errorMessage}
				onOpen={errorOpen}
				onClose={() => setErrorOpen(false)}
				onOk={() => setErrorOpen(false)}></DialogError>
			<SpinnerLoading loading={loading} />
			<DialogTitle>{<TitleDialog onClose={onClose} message={t("Confirm payment")} />}</DialogTitle>
			<div style={{ padding: "0 20px " }}>
				<Typography className={classes.titlePoli}>Política de cancelamento</Typography>
				<Typography className={classes.titlePoli}>{Utils.policyDetails(checkoutInformation, currency)}</Typography>
			</div>
			<div className={classes.container}>
				<div style={{ padding: 20 }}>
					<Avatar style={{ float: "left" }} alt={business.name} src={business.thumbnailUrl} />
					<Typography className={classes.businessName}>{business.name}</Typography>
					<Typography className={classes.businessAddress}>{business.address.street}</Typography>
				</div>
				<Divider />
				<div style={{ padding: "15px 20px" }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography className={classes.duration}>{t("Total services")}</Typography>
						<Typography className={classes.priceDetail}>
							{parseFloat(checkoutInformation?.total_value_for_services / 100).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
							})}
						</Typography>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography className={classes.duration}>{t("Discount")}</Typography>
						<Typography className={classes.priceDetail}>
							{parseFloat(checkoutInformation?.total_discounts / 100).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
							})}
						</Typography>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography className={classes.priceDetailBlue}>{t("Payment advance")}</Typography>
						<Typography className={classes.price}>
							{parseFloat(checkoutInformation?.anticipation_payment / 100).toLocaleString("pt-BR", {
								style: "currency",
								currency: currency,
							})}
						</Typography>
					</div>
				</div>
				<Divider />
				<div style={{ padding: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<Typography className={classes.cardNumber} style={{ fontWeight: "bold", color: "#3689ea", fontSize: "10px" }}>
						{pix || Utils.formatCardNumber(cardData?.number)}
					</Typography>
					{business?.currency === "BRL" && (
						<Typography
							onClick={() => {
								showUpdateInfo();
							}}
							style={{
								height: "15px",
								fontSize: "12px",
								color: "#3689ea",
							}}>
							Alterar
						</Typography>
					)}
				</div>
			</div>
			{footer()}
		</>
	);
};

export default withRouter(DialogPayConfirm);
