import { Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "./style";
import { withRouter } from "react-router";
import dayjs from "~/config/dayjs";
import { scheduleOnGoogleCalendar } from "~/utils/GoogleCalendar/GoogleCalendar";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useTranslation } from "react-i18next";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import Footer from "~/components/common/footerContainer/FooterContainer";

const DialogScheduleConfirm = (props) => {
	const { business, payload } = props.location.state;

	const classes = useStyles();
	const { t } = useTranslation();

	const checkoutPayload = localStorage.getItem("@prit-web/checkoutPayload");

	if (checkoutPayload) {
		localStorage.removeItem("@prit-web/checkoutPayload");
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (business && payload && payload?.services?.length > 0) {
			payload.services.forEach((service) => {
				let event = {
					name: service.service.serviceName,
					business: {
						addressStreet: business.address.street,
						timeZone: business.timeZone,
					},
					startTime: new Date(new Date(service.date).getTime() + 10800000).toJSON(),
					endTime: new Date(
						new Date(service.date).getTime() + 10800000 + service.service.serviceDuration * 60000
					).toJSON(),
				};
				scheduleOnGoogleCalendar(event, business.name);
			});
		}
	}, [payload]);

	const handleLinkClick = () => {
		window.location =
			"https://prit-business.onelink.me/Mxb0?pid=User_invite&deep_link_value=7adcafc1e674209531794a6ebf6b5db7";
	};

	return (
		<>
			<TitleContainer footer={<Footer />} hiddenAppDownloadBar>
				<div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
					<div style={{ margin: "20px" }}>
						<div>
							<div style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
								<div>
									<div style={{ display: "flex", padding: "20px", justifyContent: "center" }}>
										<CheckCircle style={{ color: "#009900", fontSize: "24px" }}></CheckCircle>
										<div style={{ width: "10px" }}></div>
										<Typography
											style={{
												fontSize: "16px",
												color: "#009900",
												fontWeight: "bold",
												textAlign: "center",
											}}
											className={classes.titleScheduleConfirm}>
											{t("Appointment Scheduled")}
										</Typography>
									</div>
								</div>
								<Divider />
								<div style={{ padding: "20px", justifyContent: "center" }}>
									{payload?.services?.length > 0 &&
										payload.services.map((service) => {
											return (
												<div>
													<Typography style={{ fontSize: "16px", fontWeight: "bold", color: "#666666" }}>
														{business?.name}
													</Typography>
													<Typography style={{ fontSize: "16px", color: "gray" }}>
														{`${service.service.serviceName} - ${dayjs(service.dateStart).format("DD/MM")} ${t(
															"às"
														)} ${dayjs(service.dateStart).format("HH:mm")}`}
													</Typography>
												</div>
											);
										})}
								</div>
							</div>
							<Divider style={{ margin: "32px 0" }} />
							<div style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
								<div style={{ display: "flex", padding: "20px 20px 0 20px", justifyContent: "center" }}>
									<Typography style={{ fontSize: "16px", color: "gray", textAlign: "center", width: "240px" }}>
										{t("Get to know Prit")}
									</Typography>
								</div>
								<div style={{ height: "10px" }}></div>
								<div style={{ display: "flex", padding: "0 20px 20px 20px", justifyContent: "center" }}>
									<Button
										onClick={() => {
											handleLinkClick();
										}}
										style={{
											color: "white",
											width: "100%",
											background: "rgba(54, 136, 235, 1)",
											padding: "10px",
											borderRadius: "100px",
										}}>
										{t("I want to know more")}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</TitleContainer>
			{/* <WhatsAppButton  bottom={"80px"}/> */}
		</>
	);
};

export default withRouter(DialogScheduleConfirm);
